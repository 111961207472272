module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":1,"matomoUrl":"https://matomo.bastienfoucher.com","siteUrl":"https://www.bastienfoucher.com","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","requireConsent":false,"disableCookies":true,"dev":false,"trackload":false},
    }]
